import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactDom from 'react-dom';

import classes from './C_Invoices.module.css';

import { useHttpClient } from '../../../../../hooks/http-hooks';
import ErrorModal from '../../../../../utils/ErrorModal';
import LoadingSpinner from '../../../../../utils/LoadingSpinner';

import IconButton from '../../../../../utils/IconButton';

import InvoiceForm from './comps/InvoiceForm';
import InvoiceCard from './comps/InvoiceCard';

function C_Invoices() {
	const { clearError, error, isLoading, sendRequest } = useHttpClient();
	const param = useParams();

	const [cid, setCid] = useState(param.contactId);
	const [selectedInvoice, setSelectedInvoice] = useState(null);

	/**
	 * -------------
	 * Caricamento dati
	 * -------------
	 */

	const [invocesList, setInvocesList] = useState(null);

	const getInvocesData = async () => {
		const list = await sendRequest('api/invoices/list/' + cid);

		setInvocesList(list);
	};

	useEffect(() => {
		if (invocesList) {
			evalInvoicesVisuals();
		}
	}, [invocesList]);

	/** -------------- */

	/**
	 * -------------
	 * Creazione/Modifica fattura
	 * -------------
	 */

	const [invoicesVisuals, setInvoicesVisuals] = useState(null);

	const evalInvoicesVisuals = () => {
		const _v = invocesList.map(inv => {
			return (
				<InvoiceCard
					key={inv._id}
					invoice={inv}
					setSelected={setSelectedInvoice}
				/>
			);
		});

		setInvoicesVisuals(_v);
	};

	useEffect(() => {
		console.log(selectedInvoice);
		if (selectedInvoice) {
			handleAddNewInvoice();
		}
	}, [selectedInvoice]);

	/** -------------- */
	/**
	 * -------------
	 * Creazione/Modifica fattura
	 * -------------
	 */
	const [addNewInvoice, setAddNewInvoice] = useState(false);
	const handleAddNewInvoice = () => {
		if (addNewInvoice) {
			setSelectedInvoice(null);
		}
		setAddNewInvoice(!addNewInvoice);
	};

	const postInvoiceData = async invoice => {
		const d = await sendRequest(
			'api/invoices/create',
			'POST',
			{ invoice },
			{ 'Content-Type': 'application/json' }
		);

		const updList = invocesList.map(el => {
			if (el._id === d._id) {
				return d;
			}
			return el;
		});

		setInvocesList(updList);
	};

	const openInvoiceForm = () => {
		const form = (
			<InvoiceForm
				cID={cid}
				invoice={selectedInvoice}
				saveInvoice={postInvoiceData}
				clear={handleAddNewInvoice}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	/** ----------------------------- */

	// useEffect(() => {
	// 	console.log('aggiornato');
	// }, [addNewInvoice]);

	useEffect(() => {
		getInvocesData();
	}, []);

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			{addNewInvoice && openInvoiceForm()}
			<div className={classes.wrapper}>
				<div className={classes.header}>
					<div>
						<IconButton text={'add_circle'} action={handleAddNewInvoice} />
					</div>
				</div>
				<div className={classes.invocesWrap}>{invoicesVisuals}</div>
			</div>
		</React.Fragment>
	);
}

export default C_Invoices;
