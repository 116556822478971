import React from 'react';

import classes from './InvoiceCard.module.css';

import IconButton from '../../../../../../utils/IconButton';

function InvoiceCard({ invoice, setSelected }) {
	const select = () => {
		setSelected(invoice);
	};

	return (
		<React.Fragment>
			<div className={classes.invoiceCard} onClick={select}>
				<p>{new Date(invoice.documetDate).toLocaleDateString()}</p>
				<p>{invoice.proFormaNo}</p>
				<p className={classes.bold}>
					{Number(invoice.amount).toLocaleString()}
				</p>
				{!invoice.paid && (
					<IconButton
						text={'payments'}
						style={{
							position: 'absolute',
							top: '0',
							right: '0',
							color: 'var(--ONGOING)',
						}}
					/>
				)}
				{invoice.invoiceNo && <p>{invoice.invoiceNo}</p>}
			</div>
		</React.Fragment>
	);
}

export default InvoiceCard;
